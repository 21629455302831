import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { TypeCurrentReport, useCollector } from '@f_ucs/context/collector_context';
import { useUCSContext } from '@f_ucs/context/UCS_context';
import { getSusBuys } from '@f_ucs/utils/getSusBuys';
import { Type1cUser, TypeWebUser } from '@f_ucs/context/doughnut_context';
import { getReportActivity } from '@f_ucs/utils/getReportActivity';
import { mergeActivityReports } from '@f_ucs/utils/mergeActivityReports';
import { mergeSusBuys } from '@f_ucs/utils/mergeSusBuys';
import { calculateSums } from '@f_ucs/utils/calculateSums';

export type TypeUserReport = {
    user: Type1cUser | TypeWebUser;
    data: string;
    brand: string;
    article: string;
    title: string;
    price: number;
    bestPrice: number;
    percent: number;
    vendor: string;
    suitableItems: any;
};

type TypeReportContext = {
    currentReports: TypeCurrentReport[]
    timeCutReports: TypeCurrentReport []
    timeCutReportViolations: number
    timeCutReportLostProfit: number
    percentDifferenceViolations: number
    percentDifferenceLostProfit: number
    bestOffersForSusBuys: any
    susBuys: TypeUserReport[]
    mergedActivityReports: TypeActivityReport []
    percentDifferenceSearches: number
    percentDifferenceAddBasket: number
    //methods
    setMergedActivityReports: (value: TypeActivityReport[]) => void
    setTimeCutReports: (value: TypeCurrentReport[]) => void
    setBestOffersForSusBuys: any
    exportExcelHandler: any
}

export type TypeActivityReport = {
    addBasket: number
    searches: number
    orders: number
    user: Type1cUser | TypeWebUser
}

const ReportContext = createContext<TypeReportContext | null>(null);


function ReportProvider(props: any) {

    const { currentReports, currentType } = useCollector();

    const { setTypeExport, setExportData, setIsOpenExportModal } = useUCSContext();

    const [timeCutReports, setTimeCutReports] = useState<TypeCurrentReport[]>(currentReports);
    const [timeCutReportViolations, setTimeCutReportViolations] = useState<number>(0);
    const [timeCutReportLostProfit, setTimeCutReportLostProfit] = useState<number>(0);
    const [nextPeriodViolations, setNextPeriodViolations] = useState<number>(0);
    const [nextPeriodLostProfit, setNextPeriodLostProfit] = useState<number>(0);
    const [nextPeriodSearches, setNextPeriodSearches] = useState<number>(0);
    const [nextPeriodAddBasket, setNextPeriodBasket] = useState<number>(0);
    const [percentDifferenceViolations, setPercentDifferenceViolations] = useState<number | string>(0);
    const [percentDifferenceLostProfit, setPercentDifferenceLostProfit] = useState<number | string>(0);
    const [percentDifferenceSearches, setPercentDifferenceSearches] = useState<number | string>(0);
    const [percentDifferenceAddBasket, setPercentDifferenceAddbasket] = useState<number | string>(0);
    const [bestOffersForSusBuys, setBestOffersForSusBuys] = useState<any>({});
    const [susBuys, setSusBuys] = useState<TypeUserReport[]>([]);


    useEffect(() => {
        if (timeCutReports.length > 0) {
            getTimeCutReportStatistics();
            getNextPeriodStatistics();
            setSusBuys(
                getSusBuys(timeCutReports),
            );
        }
    }, [timeCutReports]);

    useEffect(() => {
        calculatePercentageDifference();
    }, [nextPeriodLostProfit, nextPeriodViolations, timeCutReportViolations, timeCutReportLostProfit]);

    const getTimeCutReportStatistics = () => {
        if (!timeCutReports || !Array.isArray(timeCutReports)) return;

        let allViolations = 0;
        let allLostProfit = 0;
        timeCutReports.forEach((report: TypeCurrentReport) => {
            if (report.suspiciousBuys && Array.isArray(report.suspiciousBuys)) {
                allViolations += report.suspiciousBuys.length;

                report.suspiciousBuys.forEach((suspiciousBuy: any) => {
                    if (suspiciousBuy.suitableItems && Array.isArray(suspiciousBuy.suitableItems) && suspiciousBuy.suitableItems.length > 0) {
                        // Найти минимальную цену среди suitableItems
                        let minSuitablePrice = Math.min(...suspiciousBuy.suitableItems.map((item: any) => item.price));

                        // Рассчитать потерянную прибыль
                        let currentLostProfit = (suspiciousBuy.price - minSuitablePrice) * suspiciousBuy.quantity;
                        allLostProfit += currentLostProfit;
                    }
                });
            }
        });

        setTimeCutReportViolations(allViolations);
        setTimeCutReportLostProfit(allLostProfit);
    };

    const getNextPeriodStatistics = () => {
        if (!currentReports || !Array.isArray(currentReports)) return;

        let nextPeriodEndDate = new Date(timeCutReports[timeCutReports.length - 1]?.timeStart);
        nextPeriodEndDate.setDate(nextPeriodEndDate.getDate() - 1);
        let nextPeriodStartDate = new Date(nextPeriodEndDate);
        nextPeriodStartDate.setDate(nextPeriodStartDate.getDate() - (timeCutReports.length - 1));
        let allNextViolations = 0;
        let allNextLostProfit = 0;
        let allNextSearches = 0;
        let allNextAddBasket = 0;

        currentReports.forEach((report: TypeCurrentReport) => {
            if (!(report && report.timeStart)) return;

            let reportDate = new Date(report.timeStart);
            if (!(nextPeriodStartDate <= reportDate && reportDate <= nextPeriodEndDate)) return;

            if (!report.suspiciousBuys || !Array.isArray(report.suspiciousBuys)) return;

            allNextViolations += report.suspiciousBuys.length;

            report.suspiciousBuys.forEach((suspiciousBuy: any) => {
                if (!suspiciousBuy.suitableItems || !Array.isArray(suspiciousBuy.suitableItems) || suspiciousBuy.suitableItems.length === 0) return;

                let minSuitablePrice = Math.min(...suspiciousBuy.suitableItems.map((item: any) => item.price));
                let currentLostProfit = (suspiciousBuy.price - minSuitablePrice) * suspiciousBuy.quantity;
                allNextLostProfit += currentLostProfit;
            });

            if (report.reportActivity) { // Проверяем, что report.reportActivity не null или undefined
                report.reportActivity.forEach((activity: TypeActivityReport) => {
                    allNextSearches += activity.searches;
                    allNextAddBasket += activity.addBasket;
                });
            }
        });

        setNextPeriodSearches(allNextSearches);
        setNextPeriodBasket(allNextAddBasket);
        setNextPeriodViolations(allNextViolations);
        setNextPeriodLostProfit(allNextLostProfit);
    };

    const calculatePercentageDifference = () => {
        const percentViolationsDiff = nextPeriodViolations !== 0 ?
            ((timeCutReportViolations - nextPeriodViolations) / nextPeriodViolations) * 100 :
            'За предыдущий период нарушений не выявлено';
        setPercentDifferenceViolations(percentViolationsDiff);

        const percentProfitDiff = nextPeriodLostProfit !== 0 ?
            ((timeCutReportLostProfit - nextPeriodLostProfit) / nextPeriodLostProfit) * 100 :
            'За предыдущий период упущенной выгоды не выявлено';
        setPercentDifferenceLostProfit(percentProfitDiff);

        const percentSearches = nextPeriodSearches !== 0 ?
            ((calculateSums(mergeActivityReports(getReportActivity(timeCutReports), currentType)).totalSearches - nextPeriodSearches) / nextPeriodSearches) * 100 :
            'В предыдущем периоде запросы/поиски не выполнялись';
        setPercentDifferenceSearches(percentSearches);

        const percentAddBasket = nextPeriodAddBasket !== 0 ?
            ((calculateSums(mergeActivityReports(getReportActivity(timeCutReports), currentType)).totalAddBasket - nextPeriodAddBasket) / nextPeriodAddBasket) * 100 :
            'В предыдущем периоде добавлений в корзину не производилось';
        setPercentDifferenceAddbasket(percentAddBasket);
    };

    //Инфо для эксель таблицы

    const violationsDataForExportExcel = susBuys.map((el: any) => {
        let userString = '';

        if (currentType === 'web') {
            userString = (el.user.firstName || '') +
                (el.user.lastName ? ' ' + el.user.lastName : '') ||
                (el.user.userId || '');
        } else if (currentType === '1c') {
            userString = el.user.alias || el.user.key || '';
        }

        return {
            ...el,
            user: userString,
            price: el.price.toLocaleString('ru'),
            time: el?.buyTime + ' ' + el?.time?.slice(11, 16),
            perfectPrice: el?.suitableItems[0]?.price.toLocaleString('ru'),
            perDeviations: Number(
                (100 - (el?.suitableItems[0]?.price / el?.price) * 100).toFixed(2).replace(',', '.'),
            ).toLocaleString('ru'),
        };
    });

    const activityDataForExportExcel = mergeActivityReports(getReportActivity(timeCutReports), currentType).map((el: any) => {
        const userKey = el.user.key;
        const userId = el.user.userId;
        const violationsItem = mergeSusBuys(getSusBuys(timeCutReports), currentType).find(item =>
            item.user === userKey || item.user === userId
        );
        const violationsCount = violationsItem ? violationsItem.count : 0;
        let userString = '';

        if (currentType === 'web') {
            userString = (el.user.firstName || '') +
                (el.user.lastName ? ' ' + el.user.lastName : '') ||
                (el.user.userId || '');
        } else if (currentType === '1c') {
            userString = el.user.alias || el.user.key || '';
        }

        return {
            ...el,
            user: userString,
            violations: violationsCount,
        };
    })

    const exportExcelHandler = (type: 'suspiciousBuys' | 'activityEmployees' | 'UCS') => {
        const types = {
            'suspiciousBuys': () => {
                return {
                    name: 'suspiciousBuys',
                    content: violationsDataForExportExcel
                }
            },
            'activityEmployees': () => {
                return {
                    name: 'activityEmployees',
                    content: activityDataForExportExcel,
                }
            },
            'UCS': () => {
                return {
                    name: 'UCS',
                    content: {
                        suspiciousBuys: violationsDataForExportExcel,
                        activityEmployees: activityDataForExportExcel
                    }
                }
            }
        }
        const exportData = types[type]()
        setTypeExport(exportData.name);
        setExportData({ [type]: exportData.content });
        setIsOpenExportModal(true);
    }


    return (
        <ReportContext.Provider
            value={{
                currentReports,
                timeCutReports,
                timeCutReportViolations,
                timeCutReportLostProfit,
                percentDifferenceViolations,
                percentDifferenceLostProfit,
                bestOffersForSusBuys,
                susBuys,
                percentDifferenceSearches,
                percentDifferenceAddBasket,
                //methods
                setTimeCutReports,
                setBestOffersForSusBuys,
                exportExcelHandler,
            } as TypeReportContext}
            {...props}
        />
    );
}

const useReport = (): TypeReportContext => {
    const context = useContext(ReportContext);
    if (!context) {
        console.error('ReportContext');
    }
    return context as TypeReportContext;
};


export { ReportProvider, useReport };
