import stls from '@f_ucs/styles/components/report/employeeStatisticsModule/employeeStats.module.sass';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { useWindowSize } from '@f_hooks';
import { useReport } from '@f_ucs/context/report_context';
import {
    ActivityReportTable,
} from '@f_ucs/components/report/EmployeeStatisticsModule/ActivityReport/ActivityReportTable/ActivityReportTable';
import { formatPercentageMoreBetter } from '@f_ucs/utils/formatPercentage';
import { getColorMoreBetter } from '@f_ucs/utils/getColor';
import { calculateSums } from '@f_ucs/utils/calculateSums';
import { mergeActivityReports } from '@f_ucs/utils/mergeActivityReports';
import { getReportActivity } from '@f_ucs/utils/getReportActivity';
import { useCollector } from '@f_ucs/context/collector_context';
import { useDatePicker } from '@f_ucs/context/date_picker_context';
import { IconInfoTable } from '@f_qwep/components/icons/IconInfoTable';
import Tooltip from '@mui/material/Tooltip';

export function EmployeeReport() {
    const { colors } = useContext(ThemeContext);
    const { width } = useWindowSize();
    const {
        timeCutReports,
        percentDifferenceSearches,
        percentDifferenceAddBasket,
    } = useReport();
    const {
        currentType,
    } = useCollector();

    const {
        currentTime,
    } = useDatePicker();

    const [open, setOpen] = useState(false);

    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        setMobile(width < 700);
    }, [width]);


    const showActivityReport = () => {
        setOpen(!open);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={mobile ? { display: 'flex', flexDirection: 'column' } : { display: 'flex' }}
                 className={stls.cards}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '24px' }}>Статистика сотрудников
                    </div>
                    <div style={mobile ? { display: 'flex', flexDirection: 'column' } : { display: 'flex' }}
                         className={stls.cards}>
                        <Card className={stls.card}>
                            <Typography sx={{ fontSize: 14, background: colors.beta }} className={stls.title}
                                        component="div">
                                Запросы/поиски
                            </Typography>
                            <CardContent className={stls.content}>
                                <Typography>
                                    {calculateSums(mergeActivityReports(getReportActivity(timeCutReports), currentType)).totalSearches} шт
                                </Typography>
                                {currentTime !== '4' &&
                                    <div style={{ display: 'flex', gap: '8px', justifyContent: 'center', marginBottom:'20px' }}>
                                        <Tooltip
                                            title={`Разница выполненных запросов/поисков с предыдущим периодом`}
                                            arrow
                                        >
                                            <div style={{ height: '20px' }}><IconInfoTable /></div>
                                        </Tooltip>
                                        <Typography style={{ color: getColorMoreBetter(percentDifferenceSearches) }}
                                                    sx={{ textAlign: 'center', maxWidth: '80%' }}>
                                            {formatPercentageMoreBetter(percentDifferenceSearches)}
                                        </Typography>
                                    </div>}
                                <Button
                                    style={{
                                        border: `2px solid ${colors.alpha}`,
                                    }}
                                    className={stls.button}
                                    onClick={showActivityReport}>
                                    Подробнее
                                </Button>
                            </CardContent>
                        </Card>
                        <Card className={stls.card}>
                            <Typography sx={{ fontSize: 14, background: colors.beta }} className={stls.title}
                                        component="div">
                                Добавление в корзину
                            </Typography>
                            <CardContent className={stls.content}>
                                <Typography>
                                    {calculateSums(mergeActivityReports(getReportActivity(timeCutReports), currentType)).totalAddBasket} шт
                                </Typography>
                                {currentTime !== '4' &&
                                    <div style={{ display: 'flex', gap: '8px', justifyContent: 'center', marginBottom:'20px' }}>
                                        <Tooltip
                                            title={`Разница совершенных добавлений в корзину с предыдущим периодом`}
                                            arrow
                                        >
                                            <div style={{height:'20px'}}><IconInfoTable /></div>
                                        </Tooltip>
                                        <Typography
                                            style={{ color: getColorMoreBetter(percentDifferenceSearches) }}
                                            sx={{ textAlign: 'center', maxWidth: '80%' }}>
                                            {formatPercentageMoreBetter(percentDifferenceAddBasket)}
                                        </Typography>
                                    </div>}
                                <Button
                                    style={{
                                        border: `2px solid ${colors.alpha}`,
                                    }}
                                    className={stls.button}
                                    onClick={showActivityReport}>
                                    Подробнее
                                </Button>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
            {open && (
                <ActivityReportTable />
            )}
        </div>
    );
}