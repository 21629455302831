import stls from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/reportTable/reportTable.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';

export function ColumnPerDeviations ({ suitItemsLength, suitItems, index, suitItemsPrice, price }:any, idRow:any, isOpenDetails:any)  {
    const { colors } = useContext(ThemeContext);

    return (
        <div
            style={
                suitItemsLength
                    ? {
                        border: `0.5px solid ${colors.eta}`,
                        overflow: 'hidden',
                        background: 'rgba(254, 205, 82, 0.2)',
                        color:
                            idRow !== index && isOpenDetails
                                ? colors.blurColor
                                : colors.omicron,
                    }
                    : {}
            }
            className={stls.cellPrice}>
            {suitItems
                ? (
                    100 -
                    (suitItemsPrice / price) * 100
                ).toFixed(2) == '0.00'
                    ? '<0.01 %'
                    : (
                    100 -
                    (suitItemsPrice / price) *
                    100
                ).toFixed(2) + ' %'
                : ''}
        </div>
    )
}