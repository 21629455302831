import { exportExcelFetchBatchPricer } from '@f_utils/exportExcelFetch';
import { callSendFormData, callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useEffect, useState } from 'react';
import { QwepContext } from './Qwep_context';

type TypeCreateTask = {
    title: string;
    file: any;
    accounts: string[];
};

type TypeUpdateTask = {
    id: number;
    brand?: string;
    article?: string;
    isActive?: boolean;
    priceThreshold?: string;
}[];

const BatchPricerContext = createContext<any>({});

function BatchPricerProvider(props: any) {
    const { batchPricerStrictTasks, batchPricerInStock, excludePromo } = useContext(QwepContext);
    const [dashboardList, setDashboadrList] = useState([]);
    const [task, setTask] = useState([]);
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [itemId, setItemId] = useState<any>();
    const [progressList, setProgressList] = useState<any>({});
    const [openAdd, setOpenAdd] = useState(false);
    const [final, setFinal] = useState(false);

    useEffect(() => {
        const list = progressList;
        Object.entries(progressList).map((item: any) => {
            if (item[1] === '100%' || item[1] === null) {
                delete list[item[0]];
            }
        });
        setProgressList(list);
    }, [progressList]);

    const getDashboard = async (loading: boolean = false) => {
        loading && setIsLoading(true);
        const result = await callPlatformAPI('qwep/batch/get/dushboard', 'get');
        console.log('getDashboard', result);
        if (result.data.isOk) {
            setDashboadrList(result.data.result);
            setFinal(true);
            result.data.result.map(async (item: any) => {
                if (item.info === 'info: Проценка списка выполняется.') {
                    await getProgressTask(item.taskId);
                }
            });
        } else {
            setDashboadrList([]);
            setFinal(true);
        }
        loading && setIsLoading(false);
    };

    const getProgressTask = async (taskId: string) => {
        const result = await callPlatformAPI(`qwep/batch/progress/task/${taskId}`, 'get');
        if (result.data.isOk && result.data.result?.message?.progress) {
            console.log('getProgressTask', result);
            setProgressList((prev: any) => {
                return { ...prev, [taskId]: result.data.result?.message.progress };
            });
            setTimeout(async () => {
                await getProgressTask(taskId);
            }, 30000);
        } else {
            setProgressList((prev: any) => {
                return { ...prev, [taskId]: null };
            });
            setDashboadrList((prev: any) => {
                return prev.map((item: any) => {
                    if (item.taskId === taskId) {
                        return {
                            ...item,
                            info: 'warn: Проценка списка остановлена!',
                        };
                    }
                    return item;
                });
            });
        }
    };

    const createTask = async (data: TypeCreateTask) => {
        let formdata = new FormData();
        formdata.append('task-file', data.file, data.file.name);
        formdata.append('task-name', data.title);
        formdata.append('accounts', JSON.stringify(data.accounts));
        formdata.append('excludePromo', excludePromo ? '1' : '0');

        const result = await callSendFormData('qwep/batch/create/task', 'post', formdata);
        console.log('createTask', result);
        if (result.data.isOk) {
            await getDashboard();
        }
        return result.data;
    };

    const deleteTask = async (taskId: string) => {
        const result = await callPlatformAPI(`qwep/batch/delete/task/${taskId}`, 'delete');
        console.log('deleteTask', result);
        if (result.data.isOk) {
            await getDashboard();
        }
        return result.data;
    };

    const getTask = async (taskId: string) => {
        const result = await callPlatformAPI(`qwep/batch/get/task/${taskId}`, 'get');
        console.log('getTask', result);

        if (result.data.isOk) {
            setTask(result.data.result);
        }
        return result.data;
    };

    const getTaskItem = async (resultId: string, itemId: number) => {
        setItemId(itemId);
        let strict = batchPricerStrictTasks[resultId] ? batchPricerStrictTasks[resultId] : false;
        let inStock = batchPricerInStock[resultId] ? batchPricerInStock[resultId] : false;
        const result = await callPlatformAPI(
            `qwep/batch/results/task/${resultId}/${itemId}/${strict}/${inStock}`,
            'get',
        );
        console.log('getTaskItem', result);

        if (result.data.isOk) {
            setItems(result.data.result);
        }
        return result.data;
    };

    const getStatusTask = async (taskId: string) => {
        const result = await callPlatformAPI(`qwep/batch/status/${taskId}`, 'get');
        console.log('getStatusTasKKKk', result);
        return result.data;
    };

    const stopTask = async (taskId: string) => {
        const result = await callPlatformAPI(`qwep/batch/stop/task/${taskId}`, 'put', { taskId });
        if (result.data.isOk) {
            await getDashboard();
            await getTask(taskId);
        }
        console.log('stopTask', result);
        return result.data;
    };

    const startTask = async (taskId: string, accounts: string[]) => {
        const result = await callPlatformAPI('qwep/batch/start/task', 'post', {
            taskId: taskId,
            accountsList: accounts,
        });
        console.log('startTask', result);
        if (result.data.isOk) {
            await getDashboard();
            await getTask(taskId);
        }
        return result.data;
    };

    const restartTask = async (taskId: string, accounts: string[]) => {
        const result = await callPlatformAPI('qwep/batch/reset/task', 'put', {
            taskId: taskId,
            accountsList: accounts,
        });
        console.log('restartTask', result);
        if (result.data.isOk) {
            await getDashboard();
            await getTask(taskId);
        }
        return result.data;
    };

    const finishTask = async (taskId: string, accounts: string[]) => {
        const result = await callPlatformAPI('qwep/batch/finish/task', 'put', {
            taskId: taskId,
            accountsList: accounts,
        });
        console.log('finishTask', result);
        if (result.data.isOk) {
            await getDashboard();
            await getTask(taskId);
        }
        return result.data;
    };

    const updateTask = async (data: TypeUpdateTask, taskId: string, accounts: string[]) => {
        const result = await callPlatformAPI('qwep/batch/update/task', 'put', {
            taskId: taskId,
            task: data,
            accountsList: accounts,
        });
        console.log('updateTask', result);
        if (result.data.isOk) {
            await getDashboard();
            await getTask(taskId);
        }
        return result.data;
    };

    const downloadTask = async (resultId: string, titleFile: string) => {
        const result = await exportExcelFetchBatchPricer(`qwep/batch/download/task/${resultId}`, titleFile);
        return result;
    };

    return (
        <BatchPricerContext.Provider
            value={{
                dashboardList,
                isLoading,
                task,
                items,
                progressList,
                itemId,
                openAdd,
                final,

                //methods
                setOpenAdd,
                stopTask,
                getDashboard,
                createTask,
                deleteTask,
                getTask,
                getStatusTask,
                getTaskItem,
                startTask,
                restartTask,
                finishTask,
                updateTask,
                downloadTask,
            }}
            {...props}
        />
    );
}

export { BatchPricerProvider, BatchPricerContext };
